:root {
  /* text */
  --font-regular: "font-regular";
  --font-demi: "font-demi";
  --font-bold: "font-bold";
  --font-heavy: "font-heavy";
  --font-icons: "mroad-icons";
  --normal-font-size: 0.9rem;
  --normal-font-size-mobile: 0.7rem;

  /* colors */
  --identity-primary-color: #B52125;
  --input-error: #f00;
  --input-valid: #008500;
  --disable-color: #b8b8b8;
  --list-bullet-color: #b8b8b8;
  --body-background-color: #F8F8F8;
  --body-background-color-darken: #F3F3F3;

  --card-hover: #F8F8F8;
  --card-border-hover: #B8B8B8;

  --chip-valid: #51b800;
  --chip-inProgress: #ff9e68;
  --chip-expired: #ff0303;

  --interaction-color: #037cc2;
  --interaction-hover-color: #a2dffe;
  --interaction-active-color: #004275;
  --interaction-disabled-color: #ebebeb;

  --interaction-fg-color: #ffffff;
  --interaction-hover-fg-color: #037cc2;
  --interaction-active-fg-color: #ffffff;
  --interaction-disabled-fg-color: #b8b8b8;

  /* links */
  --link-color: #037cc2;
  --link-hover-color: #0f607c;
  --link-active-color: #004275;
  --link-disabled-color: #b8b8b8;
  --link-visited-color: #8b59ca;

  /* text */
  --title-text-color: #474747;
  --text-color: #474747;
  --secondary-text-color: #717171;
  --text-color-placeholder: #b8b8b8;
  --text-color-placeholder-lighten: lighten(#b8b8b8, 15%);
  --text-inverse: #ffffff;
  --footer-text-color: #ffffff;

  /* Forms */
  --form-help-object-background: #28daff;

  /* modals */
  --modal-confirm-message-color: #474747;

  /* footer */
  --footer-font-size: 0.7rem;
  --footer-font-color: var(--footer-text-color);

  /* header */
  --header-font-size: 0.9rem;
  --header-user-info-font-size: 0.85em;
  --header-user-info-color: rgba(71, 71, 71, 0.7);

  /* Menu */
  --menu-font-size: 1rem;

  /* Border */
  --border-color: #d8d8d8;
  --sidebar-menu-items-border: 1px solid rgba(0, 0, 0, 0.1);

  /* Pagination */
  --pagination-arrows-color: var(--interaction-color);
  --pagination-item-active-background: #012A42;
  --pagination-item-active-color: #fff;
  --pagination-item-color: var(--text-color);
  --pagination-arrows-disabled-color: var(--interaction-disabled-fg-color);

  /* Banner */
  --banner-warning-background-color: #FF8D24;
  --banner-warning-color: #000;
  --banner-info-background-color: #DEECFF;
  --banner-info-color: #133F71;

  /* login cards */
  --card-text-color: #474747;
  --card-button-color: #067CC2;
  --card-button-border-radius: 2rem;
  --card-help-link-color: #049DD1;
  --global-text-color: #474747;

  /* Radio buttons */
  --radio-btn-unselected-background-color: white;
  --radio-btn-selected-background-color: #012A42;
  --radio-btn-dot-color: white;
  --radio-btn-unselected-border-color: #037CC2;
  --radio-btn-selected-border-color: #012A42;

  /* Checkboxes */
  --checkbox-unselected-background-color: white;
  --checkbox-selected-background-color: #012A42;
  --checkbox-unselected-border-color: #037CC2;
  --checkbox-selected-border-color: #012A42;


  /* Badges */
  --badge-color-dark: #FFF;
  --badge-background-color-dark: #000;
  --badge-color-primary: #133F71;
  --badge-background-color-primary: #DEECFF;
  --badge-color-success: #005700;
  --badge-background-color-success: #D9FFD9;
  --badge-color-warning: #000;
  --badge-background-color-warning: rgba(255, 141, 36, 0.8);
  --badge-color-error: #A30000;
  --badge-background-color-error: #FFF0F0;

  /* PDF Viewer */
  --pdf-viewer-toolbar-background-color: #484848;
  --pdf-viewer-toolbar-color: #FFF;
}
