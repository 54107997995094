// define font vars
$font-regular: var(--font-regular);
$font-demi: var(--font-demi);
$font-bold: var(--font-bold);
$font-icons: var(--font-icons);
$normal-font-size: var(--normal-font-size);
$normal-font-size-mobile: var(--normal-font-size-mobile);

// colors
$white: #fff;
$identity-primary-color: var(--identity-primary-color);
$input-error: var(--input-error);
$input-valid: var(--input-valid);
$disable-color: var(--disable-color);
$list-bullet-color: var(--list-bullet-color);
$body-background-color: var(--body-background-color);
$body-background-color-darken: var(--body-background-color-darken);


$card-hover: var(--card-hover);
$card-border-hover: var(--card-border-hover);

$chip-valid: var(--chip-valid);
$chip-inProgress: var(--chip-inProgress);
$chip-expired: var(--chip-expired);

$interaction-color: var(--interaction-color);
$interaction-hover-color: var(--interaction-hover-color);
$interaction-active-color: var(--interaction-active-color);
$interaction-disabled-color: var(--interaction-disabled-color);

$interaction-fg-color: var(--interaction-fg-color);
$interaction-hover-fg-color: var(--interaction-hover-fg-color);
$interaction-active-fg-color: var(--interaction-active-fg-color);
$interaction-disabled-fg-color: var(--interaction-disabled-fg-color);

// links
$link-color: var(--link-color);
$link-hover-color: var(--link-hover-color);
$link-active-color: var(--link-active-color);
$link-disabled-color: var(--link-disabled-color);
$link-visited-color: var(--link-visited-color);

// text
$title-text-color: var(--title-text-color);
$text-color: var(--text-color);
$secondary-text-color: var(--secondary-text-color);
$text-color-placeholder: var(--text-color-placeholder);
$text-color-placeholder-lighten: var(--text-color-placeholder-lighten);
$text-inverse: var(--text-inverse);
$footer-text-color: var(--footer-text-color);

// Forms
$form-help-object-background: var(--form-help-object-background);

// modals
$modal-confirm-message-color: var(--modal-confirm-message-color);

// footer
$footer-font-size: var(--footer-font-size);
$footer-font-color: var(--footer-font-color);

// header
$header-font-size: var(--header-font-size);
$header-user-info-font-size: var(--header-user-info-font-size);
$header-user-info-color: var(--header-user-info-color);

// menu
$menu-font-size: var(--menu-font-size);

// Border
$border-color: var(--border-color);
$sidebar-menu-items-border: var(--sidebar-menu-items-border);

// Pagination
$pagination-arrows-color: var(--pagination-arrows-color);
$pagination-item-active-background: var(--pagination-item-active-background);
$pagination-item-active-color: var(--pagintion-item-active-color);
$pagination-item-color: var(--pagintion-item-color);
$pagination-arrows-disabled-color: var(--pagination-arrows-disabled-color);

// Banner
$banner-warning-background-color: var(--banner-warning-background-color);
$banner-warning-color: var(--banner-warning-color);
$banner-info-background-color: var(--banner-info-background-color);
$banner-info-color: var(--banner-info-color);

// cards
$card-text-color: var(--card-text-color);
$card-button-color: var(--card-button-color);
$card-button-text-color: var(--card-button-text-color);
$card-button-border-radius: var(--card-button-border-radius);
$card-help-link-color: var(--card-help-link-color);
$card-help-link-font-size: var(--card-help-link-font-size);

$font-password: 'text-security-disc';
$global-text-color: var(--global-text-color);


// Radio buttons
$radio-btn-unselected-background-color: var(--radio-btn-unselected-background-color);
$radio-btn-selected-background-color: var(--radio-btn-selected-background-color);
$radio-btn-dot-color: var(--radio-btn-dot-color);
$radio-btn-unselected-border-color: var(--radio-btn-unselected-border-color);
$radio-btn-selected-border-color: var(--radio-btn-selected-border-color);

/* Checkboxes */
$checkbox-unselected-background-color: var(--checkbox-unselected-background-color);
$checkbox-selected-background-color: var(--checkbox-selected-background-color);
$checkbox-unselected-border-color: var(--checkbox-unselected-border-color);
$checkbox-selected-border-color: var(--checkbox-selected-border-color);

// Badges
$badge-color-dark: var(--badge-color-dark);
$badge-background-color-dark: var(--badge-background-color-dark);
$badge-color-primary: var(--badge-color-primary);
$badge-background-color-primary: var(--badge-background-color-primary);
$badge-color-success: var(--badge-color-success);
$badge-background-color-success: var(--badge-background-color-success);
$badge-color-warning: var(--badge-color-warning);
$badge-background-color-warning: var(--badge-background-color-warning);
$badge-color-error: var(--badge-color-error);
$badge-background-color-error: var(--badge-background-color-error);

/* PDF Viewer */
$pdf-viewer-toolbar-background-color: var(--pdf-viewer-toolbar-background-color);
$pdf-viewer-toolbar-color: var(--pdf-viewer-toolbar-color);
