@font-face {
    font-family: 'mroad-icons';
    src:  url('/assets/fonts/icons/mroad-icons.eot?ka2sx2');
    src:  url('/assets/fonts/icons/mroad-icons.eot?ka2sx2#iefix') format('embedded-opentype'),
        url('/assets/fonts/icons/mroad-icons.ttf?ka2sx2') format('truetype'),
        url('/assets/fonts/icons/mroad-icons.woff?ka2sx2') format('woff'),
        url('/assets/fonts/icons/mroad-icons.svg?ka2sx2#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="mroad-ico-"], [class*=" mroad-ico-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'mroad-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mroad-ico-accueil:before {
    content: "\e900";
}
.mroad-ico-agenda:before {
    content: "\e901";
}
.mroad-ico-aide:before {
    content: "\e902";
}
.mroad-ico-check:before {
    content: "\e903";
}
.mroad-ico-chevron_bas:before {
    content: "\e904";
}
.mroad-ico-chevron_droite:before {
    content: "\e905";
}
.mroad-ico-chevron_gauche:before {
    content: "\e906";
}
.mroad-ico-chevron_haut:before {
    content: "\e907";
}
.mroad-ico-commentaire:before {
    content: "\e908";
}
.mroad-ico-courrier:before {
    content: "\e909";
}
.mroad-ico-croix:before {
    content: "\e90a";
}
.mroad-ico-dupliquer:before {
    content: "\e90b";
}
.mroad-ico-education:before {
    content: "\e90c";
}
.mroad-ico-emploi_social:before {
    content: "\e90d";
}
.mroad-ico-en_cours_activite:before {
    content: "\e90e";
}
.mroad-ico-erreur:before {
    content: "\e90f";
}
.mroad-ico-favoris_off:before {
    content: "\e910";
}
.mroad-ico-favoris_on:before {
    content: "\e911";
}
.mroad-ico-fichier:before {
    content: "\e912";
}
.mroad-ico-fichier_ajout:before {
    content: "\e913";
}
.mroad-ico-fichier_alt:before {
    content: "\e914";
}
.mroad-ico-fichier_doc:before {
    content: "\e915";
}
.mroad-ico-fichier_pdf:before {
    content: "\e916";
}
.mroad-ico-fiscalite:before {
    content: "\e917";
}
.mroad-ico-fleche:before {
    content: "\e918";
}
.mroad-ico-Icon-GVT:before {
    content: "\e919";
    color: #cd1a25;
}
.mroad-ico-image:before {
    content: "\e91a";
}
.mroad-ico-information:before {
    content: "\e91b";
}
.mroad-ico-insatisfait:before {
    content: "\e91c";
}
.mroad-ico-justice:before {
    content: "\e91d";
}
.mroad-ico-lien_externe:before {
    content: "\e91e";
}
.mroad-ico-liens_utiles:before {
    content: "\e91f";
}
.mroad-ico-logement:before {
    content: "\e920";
}
.mroad-ico-masquer:before {
    content: "\e921";
}
.mroad-ico-menu_deconnexion:before {
    content: "\e922";
}
.mroad-ico-modifications:before {
    content: "\e923";
}
.mroad-ico-moins:before {
    content: "\e924";
}
.mroad-ico-nationalite_residence:before {
    content: "\e925";
}
.mroad-ico-nav:before {
    content: "\e926";
}
.mroad-ico-nav_bullet:before {
    content: "\e927";
}
.mroad-ico-parametres:before {
    content: "\e928";
}
.mroad-ico-parcours_guides:before {
    content: "\e929";
}
.mroad-ico-plus:before {
    content: "\e92a";
}
.mroad-ico-profils:before {
    content: "\e92b";
}
.mroad-ico-recherche:before {
    content: "\e92c";
}
.mroad-ico-reseaux_publications:before {
    content: "\e92d";
}
.mroad-ico-satisfait:before {
    content: "\e92e";
}
.mroad-ico-securite_prevention:before {
    content: "\e92f";
}
.mroad-ico-social_sante_famille:before {
    content: "\e930";
}
.mroad-ico-telecharger:before {
    content: "\e931";
}
.mroad-ico-telephone:before {
    content: "\e932";
}
.mroad-ico-transports_mobilite:before {
    content: "\e933";
}
.mroad-ico-upload:before {
    content: "\e934";
}
.mroad-ico-usager:before {
    content: "\e935";
}
.mroad-ico-video:before {
    content: "\e936";
}
.mroad-ico-voir:before {
    content: "\e937";
}
.mroad-ico-voir_plus:before {
    content: "\e938";
}
