@font-face {
    font-family: "font-regular";
    src: url("/assets/fonts/AvenirNextLTPro-Regular.otf");
}

@font-face {
    font-family: "font-demi";
    src: url("/assets/fonts/AvenirLTStd-Medium.otf");
}

@font-face {
    font-family: "font-bold";
    src: url("/assets/fonts/AvenirLTStd-Black.otf");
}

@font-face {
    font-family: "font-heavy";
    src: url("/assets/fonts/AvenirLTStd-Heavy.otf");
}